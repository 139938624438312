import { Customer } from "../../../types/Customer";

export interface InitializeBookingResponse {
    bookingReference: string;
}

export function initializeBooking(customer: Customer): Promise<InitializeBookingResponse> {
    return fetch('/api/booking',
        {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(customer)
        }
    ).then(response => {
        if (response.ok) {
            return response.json();
        }
        return Promise.reject(response.status);
    });
}

export async function cancelReservation(bookingId: string) {
    fetch('/api/booking/' + bookingId,
        {
            method: 'DELETE'
        });
}

export interface SubmitTicketResponse {
    reference: string[];
}

export function submitTicket(bookingId: string, path: string, ticket: any): Promise<SubmitTicketResponse> {
    return fetch(`/api/booking/${bookingId}/${path}`,
        {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(ticket)
        }
    ).then(response => {
        if (response.ok) {
            return response.json();
        }
        return Promise.reject(response.status);
    });
}