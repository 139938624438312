import { UseFormRegisterReturn } from "react-hook-form";

interface SelectProps<T> {
    hasError?: boolean;
    onChange: (value: T | null) => void;
    defaultValue?: string | ReadonlyArray<string> | number;
    defaultOption?: string
    options: SelectOption<T>[];
    register?: UseFormRegisterReturn,
    errorMessage?: string,
    label: string,
    name: string
}

interface SelectOption<T> {
    text: string;
    value: T;
}

export default function Select<T>({
    hasError = false,
    onChange,
    defaultValue,
    defaultOption,
    options,
    register,
    errorMessage,
    label,
    name
}: SelectProps<T>) {

    function mapValueOnChange(e: React.FormEvent<HTMLSelectElement>) {
        let value = parseInt(e.currentTarget.value);
        if (value === 0) {
            onChange(null);
            return;
        } else {
            onChange(options[value - 1].value);
        }
    }

    return <>
        <div className="input-wrapper">
            <label className="label" htmlFor={name}>{label}</label>
            <select className={hasError ? 'input-error' : 'input-valid'}
                id={name}
                name={name}
                onInput={mapValueOnChange}
                defaultValue={defaultValue}
                {...register}
            >
                {defaultOption &&
                    <option value={0} key={0}>{defaultOption}</option>
                }
                {options.map(
                    (option, index) =>
                        <option value={index + 1} key={index + 1}>
                            {option.text}
                        </option>
                )}
            </select>
            {hasError &&
                <span className='input-error-text'>
                    {errorMessage}
                </span>
            }
        </div>
    </>
}