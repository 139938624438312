import './footer.css'

export default function Footer() {

    return <>
        <footer>
            <div className='contact-info'>
                <h4>KONTAKT</h4>
                <a href="tel:+49 4101 85 86 603">+49 4101 85 86 603</a>
                <br />
                <a href="mailto:moinmoin@cablesport.de">moinmoin@cablesport.de</a> <br />
                Cablesport Arena Pinneberg <br />
                Burmeisterallee 2 <br />
                25421 Pinneberg Germany
            </div>
            <div className='contact-social'>
                <div className='social-icons'>
                    <a href="https://www.facebook.com/cablesport" target="_blank" rel="noreferrer">
                        <div className='contact-icon contact-icon__fb'></div>
                    </a>
                    <a href="https://www.instagram.com/cablesport" target="_blank" rel="noreferrer">
                        <div className='contact-icon contact-icon__insta'></div>
                    </a>
                    <a href="https://www.youtube.com/channel/UCrFdWPA5cx3v5tdIgzwwJZw" target="_blank" rel="noreferrer">
                        <div className='contact-icon contact-icon__youtube'></div>
                    </a>
                    <a href="https://vimeo.com/user24463188" target="_blank" rel="noreferrer">
                        <div className='contact-icon contact-icon__vimeo'></div>
                    </a>
                </div>
                <div className='contact-legal'>
                    <a href="https://cablesport.de/agbs/" target="_blank" rel="noreferrer">AGB's</a>
                    <a href="https://cablesport.de/impressum/" target="_blank" rel="noreferrer">Impressum</a>
                    <a href="https://cablesport.de/datenschutzerklaerung/" target="_blank" rel="noreferrer">Datenschutzerklärung</a>
                </div >
            </div >
            <div className='contact-icons'>
                <a href="tel:+4941018586603">
                    <div className='contact-icon contact-icon__phone'></div>
                </a>
                <a href="mailto:moinmoin@cablesport.de">
                    <div className='contact-icon contact-icon__mail'></div>
                </a>
            </div>
        </footer >
    </>
}