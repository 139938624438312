import FAQ from "../../../components/faq/FAQ";

export default function VoucherFaq() {
    return <>
        <FAQ entries={
            [
                {
                    headline: 'Allgemeine Infos für unsere Gutscheine',
                    body: <p>
                        Bei unseren Gutscheinen handelt es sich um Wertgutscheine, die sowohl für den Wassersport als auch im Strandklub einlösbar sind. <br />
                        Die Preise der oben vorgeschlagenen Budgets beziehen sich hierbei auf die aktuelle Saison und sind nicht verbindlich.
                        <br />Es besteht kein Anspruch auf eine explizite Leistung, sondern es gilt immer der Geldwert eines Gutscheins.
                        <br /><br />
                        Für die Einlösungen im öffentlichen Betrieb bedarf es keiner Reservierung.
                        <br /><br />Unsere Gutscheine besitzen die gesetzliche Gültigkeit von 3 Jahren.
                    </p>
                },
                {
                    headline: 'Zahlungsoptionen',
                    body: <p>Ihr habt die Möglichkeit per Kreditkarte, Sofortüberweisung oder PayPal-Konto zu bezahlen.</p>
                },
                {
                    headline: 'Buchung und Stornierung',
                    body: <p>
                        Nach der Bestellung des Gutscheins bekommst Du Deinen individuellen Gutschein mit Deiner Gutscheinnummer sofort per Mail zugeschickt.
                        <br />Eine Stornierung des Gutscheins ist nach Bestellabschluss <strong>nicht</strong> mehr möglich.
                    </p>
                }
            ]
        }
        />
    </>
}