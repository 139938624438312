import { Link } from "react-router-dom";
import './Card.css';

interface CardProps {
    linkTo: string;
    linkTarget?: React.HTMLAttributeAnchorTarget;
    thumbnailClass: string;
    title: string;
    description?: string;
}

export default function Card(props: CardProps) {
    return <>
        <div className="card">
            <Link to={props.linkTo} target={props.linkTarget}>
                <div className={`card__thumbnail ${props.thumbnailClass}`}></div>
                <div className="card__title">{props.title}</div>
                {props.description && <div className="card__description" dangerouslySetInnerHTML={{ __html: props.description }} />}
            </Link>
        </div>
    </>
}