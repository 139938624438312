export interface Course {
    reference: string;
    title: string;
    description: string;
    remainingSpots: number;
    price: number;
    date: string;
    hasEquipmentOption: boolean;
    priceOfEquipment: number;
    hasIntroductionOption: boolean;
    hasObstacleEquipmentOption: boolean;
    priceOfObstacleEquipment: number;
}

export function findUpcomingCourses(segment?: string): Promise<Course[]> {
    return fetch('/api/booking/courses/' + segment, {
        method: 'GET',
        headers: {
            'Accept': 'application/json'
        }
    })
        .then(res => {
            if (res.ok) {
                return res.json();
            } else {
                Promise.reject(res.status);
            }
        });
}

export function findCourseData(reference?: string): Promise<Course> {
    return fetch('/api/booking/course/' + reference, {
        method: 'GET',
        headers: {
            'Accept': 'application/json'
        }
    })
        .then(res => {
            if (res.ok) {
                return res.json();
            } else {
                return null;
            }
        });
}

export interface CourseCategory {
    segment: string;
    title: string;
    description: string;
}

export function findCourseCategories(): Promise<CourseCategory[]> {
    return fetch('/api/booking/courseCategories', {
        method: 'GET',
        headers: {
            'Accept': 'application/json'
        }
    })
        .then(res => {
            if (res.ok) {
                return res.json();
            } else {
                Promise.reject(res.status);
            }
        });
}

export interface SubmitCourseTicketRequest {
    course: string;
    participants: CourseParticipant[];
}

export interface CourseParticipant {
    firstName: string;
    age: number;
    needsEquipment: boolean;
    needsIntroduction: boolean;
    needsObstacleEquipment: boolean;
}