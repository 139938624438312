import { useEffect, useState } from "react";
import { H3 } from "../../../../components/headline/Headline";
import "./OrderOverview.css";
import OrderOverviewTable from "./OrderOverviewTable";
import { Buttons } from "../../../../components/button/Button";
import { OrderResponse, getOrder } from "../api/OrderApi";
import CashInVoucherForm from "./CashInVoucherForm";

interface OrderOverviewProps {
    bookingId: string;
    onSubmit: () => void;
}

export default function OrderOverview(props: OrderOverviewProps) {

    const [order, setOrder] = useState<OrderResponse | null>(null);

    useEffect(() => {
        if (!order) {
            getOrder(props.bookingId)
                .then(
                    order => setOrder(order)
                );
        }
    }, [order, props.bookingId]);

    return <>
        {
            order && <>
                <div className="orderOverviewForm">
                    <H3 text="Bestellübersicht" />
                    <OrderOverviewTable order={order} />
                    <H3 text="Online-Gutscheincode einlösen" />
                    <CashInVoucherForm bookingId={props.bookingId} onValidVoucherCode={() => setOrder(null)} />
                </div>
                <Buttons submitButtonText={order.totalPrice > 0 ? 'Bezahlen' : 'Buchen'} submitButtonAction={props.onSubmit} />
            </>
        }
    </>
}