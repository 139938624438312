interface ThirdPartyScriptProps {
    src: string | undefined;
    id: string;
}

export function ThirdPartyScript({ src, id }: ThirdPartyScriptProps) {
    const existingScript = document.getElementById(id);
    if (src && !existingScript) {
        const script = document.createElement('script');
        script.src = src;
        script.id = id;
        document.body.appendChild(script);
    }
    return <></>
}