import { HTMLInputTypeAttribute, useState } from "react";
import { UseFormRegisterReturn } from "react-hook-form";
import './input.css';

export interface InputFieldProps {
    register: UseFormRegisterReturn,
    type?: HTMLInputTypeAttribute
    name: string,
    placeholder: string,
    hasError: boolean,
    errorMessage?: string,
    onChange?: React.EventHandler<React.FormEvent<HTMLInputElement>>;
    label: string;
    additionalClasses?: string;
    maxLength?: number;
}

export function InputField(props: InputFieldProps): JSX.Element {

    const [numberOfCharactersUser, setNumberOfCharactersUsed] = useState<number>(0);

    return <>
        <div className={`input-wrapper ${props.additionalClasses ? props.additionalClasses : ''}`}>
            <label className="label" htmlFor={props.name}>{props.label}</label>
            <input {...props.register}
                id={props.name}
                type={props.type || 'text'}
                placeholder={props.placeholder}
                className={props.hasError ? 'input-error' : 'input-valid'}
                onChange={(event) => {
                    if (props.onChange) {
                        props.onChange(event);
                    }
                    setNumberOfCharactersUsed(event.currentTarget.value.length || 0);
                }}
                onKeyDown={props.type === 'number' ?
                    (e: React.KeyboardEvent<HTMLInputElement>) => {
                        if (!/[0-9]/.test(e.key) && !(e.key === "Backspace") && !(e.key === "Tab")) {
                            e.preventDefault();
                        }
                    } : undefined}
                name={props.name}
                maxLength={props.maxLength}
            />
            {props.hasError && props.errorMessage &&
                <span className='input-error-text'>
                    {props.errorMessage}
                </span>
            }
            {props.maxLength && <span className="remainingCharactersIndicator">{numberOfCharactersUser} / {props.maxLength} Zeichen</span>}
        </div>
    </>
}
