import preview from '../../../../assets/images/sample-voucher.png';
import { Buttons } from '../../../../components/button/Button';
import { H3 } from '../../../../components/headline/Headline';
import { Voucher } from '../../../../types/Ticket';
import './VoucherPreview.css';

interface VoucherPreviewProps {
    onCancel: () => void;
    onComplete: () => void
    voucher?: Voucher
}

export default function VoucherPreview(props: VoucherPreviewProps) {
    return <>
        <H3 text='So könnte Dein Gutschein aussehen' />
        <div className='voucher-preview'>
            <img src={preview} alt='Vorschau' className='voucher-preview-image' />
            <div className='voucher-preview-content'>
                <input className="voucher-preview-headline" readOnly={true} disabled={true} value={props.voucher?.headline} />
                <textarea className="voucher-preview-text" readOnly={true} disabled={true} value={props.voucher?.text} />
                <div className='voucher-preview-credit'>
                    <span>{props.voucher?.credit},- €</span>
                </div>
            </div>
        </div>
        <Buttons
            submitButtonText='Weiter'
            submitButtonAction={props.onComplete}
            backButtonText='Zurück'
            backButtonAction={props.onCancel}></Buttons>
    </>
}