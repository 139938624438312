import './button.css';

export interface ButtonProps {
    submitButtonText?: string;
    submitButtonDisabled?: boolean | false;
    submitButtonAction?: () => void;
    backButtonText?: string;
    backButtonDisabled?: boolean | false;
    backButtonAction?: () => void;
}

export function Buttons(props: ButtonProps) {
    let hasSubmitButton: boolean = props.submitButtonText !== undefined;
    let hasBackButton: boolean = props.backButtonText !== undefined && props.backButtonAction !== undefined;

    return <>
        {hasBackButton && hasSubmitButton &&
            <div className='button-wrapper has-multiple-buttons'>
                <button type="button" className='secondary' onClick={props.backButtonAction} disabled={props.backButtonDisabled}>
                    {props.backButtonText}
                </button>
                <button type="submit" className='primary' disabled={props.submitButtonDisabled} onClick={props.submitButtonAction}>{props.submitButtonText}</button>
            </div>
        }
        {
            hasBackButton && !hasSubmitButton &&
            <div className='button-wrapper has-back-button'>
                <button type="button" className='secondary' onClick={props.backButtonAction} disabled={props.backButtonDisabled}>
                    {props.backButtonText}
                </button>
            </div>
        }
        {
            !hasBackButton && hasSubmitButton &&
            <div className='button-wrapper has-submit-button'>
                <button type="submit" className='primary' disabled={props.submitButtonDisabled} onClick={props.submitButtonAction}>{props.submitButtonText}</button>
            </div>
        }
    </>

}