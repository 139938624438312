import { UseFormRegisterReturn } from "react-hook-form";
import './input.css';
import { useState } from "react";

export interface TextareaProps {
    register: UseFormRegisterReturn;
    name: string;
    placeholder?: string;
    hasError: boolean;
    errorMessage?: string;
    label: string;
    additionalClasses?: string;
    maxLength?: number;
    maxRows?: number;
}

export function Textarea(props: TextareaProps): JSX.Element {

    const [numberOfCharactersUser, setNumberOfCharactersUsed] = useState<number>(0);

    return <>
        <div className={`input-wrapper ${props.additionalClasses ? props.additionalClasses : ''}`}>
            <label className="label" htmlFor={props.name}>{props.label}</label>
            <textarea {...props.register}
                id={props.name}
                placeholder={props.placeholder}
                className={props.hasError ? 'input-error' : 'input-valid'}
                name={props.name} maxLength={props.maxLength}
                onChange={(event) => {
                    setNumberOfCharactersUsed(event.currentTarget.value.length || 0);
                }}
                rows={props.maxRows}
                wrap="hard"
            />

            {props.hasError && props.errorMessage &&
                <span className='input-error-text'>
                    {props.errorMessage}
                </span>
            }
            {props.maxLength && <span className="remainingCharactersIndicator">{numberOfCharactersUser} / {props.maxLength} Zeichen</span>}
        </div>
    </>
}
