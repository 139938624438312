import { PayloadAction, configureStore, createSlice } from "@reduxjs/toolkit";
import { Booking } from "../types/Booking";
import { Customer } from "../types/Customer";
import { Ticket } from "../types/Ticket";

const bookingSlice = createSlice({
    name: 'booking',
    initialState: {} as Booking,
    reducers: {
        bookingInitialized(state, action) {
            return state = action.payload;
        }
    }
});

export const { bookingInitialized } = bookingSlice.actions;

const customerSlice = createSlice({
    name: 'customer',
    initialState: {} as Customer,
    reducers: {
        customerAdded(state, action: PayloadAction<Customer>) {
            return state = action.payload;
        }
    }
});

export const { customerAdded } = customerSlice.actions;

const ticketSlice = createSlice({
    name: 'ticket',
    initialState: {} as Ticket,
    reducers: {
        ticketAdded(state, action: PayloadAction<Ticket>) {
            return state = action.payload;
        }
    }
});

export const { ticketAdded } = ticketSlice.actions;

export const store = configureStore({
    reducer: {
        booking: bookingSlice.reducer,
        customer: customerSlice.reducer,
        ticket: ticketSlice.reducer
    }
});

export type RootState = ReturnType<typeof store.getState>