// @ts-ignore
import LoadingOverlay from 'react-loading-overlay';
LoadingOverlay.propTypes = undefined;

interface LoaderProps {
    active: boolean
    children?: any;
}

export default function Loader(props: LoaderProps): JSX.Element {
    return <LoadingOverlay
        active={props.active}
        spinner={true}
        styles={{
            content: () => ({
            })
        }}
    >
        {props.children}
    </LoadingOverlay>
}