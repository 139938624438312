import { useEffect, useState } from "react";
import Error from "./steps/Error";
import { H2, H3 } from "../../components/headline/Headline";
import { Ticket } from "../../types/Ticket";
import { useDispatch, useSelector } from "react-redux";
import { RootState, bookingInitialized, customerAdded, ticketAdded } from "../../state/store";
import { CustomerType } from "./identification/types/CustomerIdentification";
import { Customer } from "../../types/Customer";
import CustomerIdentificationForm from "./identification/CustomerIdentificationForm";
import PaymentHandler from "./payment/PaymentHandler";
import Loader from "../../components/loader/Loader";
import { initializeBooking, submitTicket } from "./api/BookingApi";
import Select from "../../components/select/Select";

interface BookingFlowProps {
    headline?: string;
    ticket: {
        headline?: string;
        handler: (
            onTicketConfigurationCompleted: (ticket: Ticket) => void,
            selectedCustomerType: CustomerType,
        ) => JSX.Element;
        submitCall: {
            path: string;
            mapper: (ticket: Ticket) => any;
        };
    };
    customer: {
        types: CustomerType[];
    }
}

export default function BookingFlow(props: BookingFlowProps) {

    const [selectedCustomerType, setSelectedCustomerType] = useState<CustomerType | undefined>(determineCustomerType());
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [step, setStep] = useState<'TICKET' | 'CUSTOMER' | 'PAYMENT' | 'ERROR'>('TICKET');
    const dispatch = useDispatch();

    const currentTicket = useSelector((state: RootState) => state.ticket);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [step]);

    function ticketConfigurationCompleted(ticket: Ticket) {
        dispatch(ticketAdded(ticket));
        setStep('CUSTOMER');
    }

    function customerIdentificationCompleted(customer: Customer) {
        dispatch(customerAdded(customer));
        setIsLoading(true);

        initializeBooking(customer)
            .then(
                (r) => {
                    if (currentTicket) {
                        dispatch(bookingInitialized({ id: r.bookingReference }));
                        return submitTicket(r.bookingReference, props.ticket.submitCall.path, props.ticket.submitCall.mapper(currentTicket));
                    } else {
                        return Promise.reject('no Ticket');
                    }
                }
            )
            .then(
                () => setStep('PAYMENT')
            )
            .catch(error)
            .finally(
                () => setIsLoading(false)
            );
    }

    function error() {
        setStep('ERROR');
    }

    function determineCustomerType(): CustomerType | undefined {
        return props.customer.types.length === 1 ? props.customer.types[0] : undefined;
    }

    return <>
        <Loader active={isLoading}>
            {
                props.headline && <H2 text={props.headline} />
            }
            {
                step === 'TICKET' &&
                <>
                    {
                        props.ticket.headline && <H3 text={props.ticket.headline} />
                    }
                    {
                        selectedCustomerType ? props.ticket.handler(ticketConfigurationCompleted, selectedCustomerType)
                            : <Select
                                name="customer-type-selector"
                                label="Personengruppe"
                                onChange={
                                    (value: CustomerType | null) => {
                                        if (value != null) {
                                            setSelectedCustomerType(value);
                                        }
                                    }
                                }
                                defaultOption="Bitte wählen"
                                options={
                                    [
                                        {
                                            text: 'Ich buche privat',
                                            value: 'PERSON'
                                        },
                                        {
                                            text: 'Ich buche für eine Firma',
                                            value: 'COMPANY'
                                        },
                                        {
                                            text: 'Ich buche für eine Schule / einen Jugendverein ***',
                                            value: 'SCHOOL_OR_CLUB'
                                        }
                                    ]
                                }
                            />
                    }
                </>
            }
            {
                step === 'CUSTOMER' && selectedCustomerType &&
                <CustomerIdentificationForm
                    customerType={selectedCustomerType}
                    onComplete={customerIdentificationCompleted}
                    onCancel={() => setStep('TICKET')}
                />
            }
            {
                step === 'PAYMENT' &&
                <PaymentHandler
                    onCancel={() => setStep('CUSTOMER')}
                    onComplete={() => { }}
                    onError={error}
                />
            }
            {
                step === 'ERROR' && <Error />
            }
        </Loader>
    </>

}