// eslint-disable-next-line no-multi-str
export const prices = <>
    <p>
        Eine Liftmiete beinhaltet einen 2 Stunden - Slot, eine Einweisung, die Betreuung unseres Personals und das benötigte Equipment, wie Neoprenanzüge, Westen, Helme, Wasserski, Kneeboards und Wakeboards.
        Für Feature - Boards(Wakeboards für unsere Rampen) berechnen wir 15€ pro Board extra.
    </p>
    <p>Alle Preise beziehen sich auf die Mindestmiete. Hier sind 15 Wassersportler inklusive. Jede weitere Person wird am Veranstaltungstag zusätzlich abgerechnet.</p>
    <p className="pricesWrapper">
        <span className="priceInterval">Jeder Tag von 7 – 9 Uhr</span>
        <span className="price">375 €</span>
        <span className="priceHint">ab 16 Teilnehmern 25 € / Person</span>
    </p>
    <p className="pricesWrapper">
        <span className="priceInterval">Montag – Freitag 9 - 11 Uhr, 11 – 13 Uhr und 13 – 15 Uhr <strong>*</strong></span>
        <span className="price">450 €</span>
        <span className="priceHint">ab 16 Teilnehmern 30 € / Person</span>
    </p>
    <p className="pricesWrapper">
        <span className="priceInterval">Wochenende und Feiertage 9 – 11 Uhr</span>
        <span className="price">510 €</span>
        <span className="priceHint">ab 16 Teilnehmern 34 € / Person</span>
    </p><p className="pricesWrapper">
        <span className="priceInterval">Wochenende und Feiertage 11 – 13 Uhr</span>
        <span className="price">570 €</span>
        <span className="priceHint">ab 16 Teilnehmern 38 € / Person</span>
    </p >
    <p>
        Ihr wollt einen zusätzlichen Coach während der gesamten Liftmiete für eine noch intensivere Betreuung? Kein Problem, bucht Euch den Coach oben einfach mit dazu!
    </p>
    <p>
        Gern könnt Ihr den Lift auch innerhalb unserer regulären Öffnungen exklusiv für Euch buchen.
        <br />Schreibt uns hierzu einfach eine Mail an <a href="mailto:moinmoin@cablesport.de">moinmoin@cablesport.de</a>.
        <br />Eine Stunde exklusive Liftmiete im regulären Betrieb: 1.400 €
    </p>
    <p className="footnote">
        * Die 13 – 15 Uhr Miete steht an Feiertagen und in den Sommerferien nicht zur Verfügung.
    </p>
</>;

export const pricesSchoolsOrClubs = <>
    <p>
        Eine Liftmiete beinhaltet einen 2 Stunden - Slot, eine Einweisung, die Betreuung unseres Personals und das benötigte Equipment, wie Neoprenanzüge, Westen, Helme, Wasserski, Kneeboards und Wakeboards.
        Für Feature - Boards(Wakeboards für unsere Rampen) berechnen wir 15€ pro Board extra.
    </p>
    <p>Alle Preise beziehen sich auf die Mindestmiete. Hier sind 15 Wassersportler inklusive. Jede weitere Person wird am Veranstaltungstag zusätzlich abgerechnet.</p>
    <p className="pricesWrapper">
        <span className="priceInterval">Montag – Freitag 9 - 11 Uhr <strong>**</strong>, 11 – 13 Uhr und 13 – 15 Uhr <strong>*</strong></span>
        <span className="price">300 €</span>
        <span className="priceHint">ab 16 Teilnehmern 20 € / Person</span>
    </p >
    <p>
        Ihr wollt einen zusätzlichen Coach während der gesamten Liftmiete für eine noch intensivere Betreuung? Kein Problem, bucht Euch den Coach oben einfach mit dazu!
    </p>
    <p>
        Bitte beachtet, dass wir lediglich den Veranstaltungsort zur Verfügung stellen und nicht der ausrichtende Veranstalter sind. Einverständniserklärungen etc. sind von unserer Seite aus daher nicht nötig. Bitte klärt im Vorwege mit Eurer Schulleitung / Vereinsleitung, welche Auflagen Ihr zu beachten habt.
        <br /><br />Bei versehentlicher Fehlbuchung, behalten wir uns vor, den Differenzbetrag zum entsprechenden Tarif am Veranstaltungstag abzurechnen.
    </p>
    <p className="footnote">
        * Die 13 – 15 Uhr Miete steht an Feiertagen und in den Sommerferien nicht zur Verfügung.
        <br />
        ** Das Angebot gilt ausschließlich unter der Woche (Montag bis Freitag) und NICHT an Feiertagen.
        <br />
        *** Vereine dessen Durchschnittsalter bei höchstens 21 Jahren liegt.
    </p>
</>

export const info = <>
    <p>
        Bitte beachtet, dass Ihr vor jeder Gruppenmiete eine halbe Stunde vor der Buchungszeit vor Ort seid, damit wir genug Zeit für das Umziehen und die Einweisung haben.
    </p>
    <p>
        Hier findet Ihr vorab schon einmal unser Einweisungsvideo. Wir empfehlen dieses bereits im Vorweg zu schauen:
        <br />
        <a href="https://youtu.be/bm4eABlhSBQ" target='_blank' rel="noreferrer">Einweisungsvideo</a>
    </p>
    <p>Ihr braucht nur Badesachen und ein Handtuch mitbringen, den Rest bekommt Ihr von uns.</p>
    <p>Für Eure Sachen haben wir Fächer am Startdock, auf diese Ihr eine gute Sicht habt. Wir empfehlen jedoch auf unnötige Wertgegenstände zu verzichten.</p>
    <p>Wir empfehlen für einen zwei Stunden Slot eine Gruppengröße von max. 25-30 Personen.</p>
    <p>Dieser Sport ist <strong>NICHT</strong> für Nichtschwimmer geeignet.
    </p>
</>

export const cancelationInfo = <>
    <p>
        Alle Buchungen müssen mindestens 7 Tage im Voraus getätigt werden, damit wir Euch einplanen können. Für kurzfristige Anfragen schreibt uns gern eine Mail an <a href="mailto:moinmoin@cablesport.de">moinmoin@cablesport.de</a>.
    </p>
    <p>
        Solltet Ihr eine bereits gebuchte Liftmiete nicht wahrnehmen können, könnt Ihr diese bis zu 5 Tage nach der Buchung stornieren<strong>*</strong>. Danach behalten wir uns vor, die Liftmiete im vollem Umfang einzubehalten. Schreibt uns hierzu einfach eine Mail mit Euren Buchungsdaten inkl. Buchungsnummer an <a href="mailto:moinmoin@cablesport.de">moinmoin@cablesport.de</a>.
        <br />Ihr möchtet eine bereits gebuchte Liftmiete verschieben? Schreibt und gerne eine Mail, um ggfs. anfallende Stornierungsgebühren zu vermeiden.
    </p>
    <p className="footnote">
        *Nur möglich bis zu 10 Tagen vor der Liftmiete, danach ist eine kostenfreie Stornierung ausgeschlossen. Es fallen ggfs. Stornierungsgebühren i.H.v. 15% an.
    </p>
</>

export const alternatives = <>
    <p>
        Viele Gruppen entscheiden sich für eine exklusive Liftmiete, obwohl sie weniger als 15 Personen sind. Die Vorteile:
    </p>
    <p>
        &emsp;-&emsp;2 Stunden den Lift für sich allein
        <br />
        &emsp;-&emsp;Man ist unter sich
        <br />
        &emsp;-&emsp;Exklusive Betreuung durch den Hebler während der gesamten Liftzeit
        <br />
        &emsp;-&emsp;Vermehrte Starts und dadurch schnellerer Lernfortschritt und mehr Spaß
    </p>
    <p>
        Alternativ bieten wir ab einer Gruppengröße von 10 Personen ein Gruppenpaket für den öffentlichen Betrieb an. Weitere Infos findet Ihr hier: <a href="https://cablesport.de/preise/" target="_blank" rel="noreferrer">https://cablesport.de/preise</a>
    </p>

</>