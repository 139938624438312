import './app.css';
import Card from './components/card/Card';
import { H2 } from './components/headline/Headline';

function App() {

  return <>
    <H2 text='Unser Angebot' />
    <div className="cards">
      <Card
        linkTo='/gruppenbuchungen'
        thumbnailClass='card__largeGroup'
        title='Exklusive Gruppenmiete'
        description='Unsere Cablesport Arena Pinneberg ganz für Euch allein. Hier wird Euer Traum war! Bucht hier Euren Wunschtermin.'
      />
      <Card
        linkTo='/kurse'
        thumbnailClass='card__courses'
        title='Kurse'
        description='Ihr wollt den Wassersport erlernen oder Eure Skills verfeinern? Dann bucht Euren passenden Kurs jetzt online.' />
      <Card
        linkTo='/gutscheine'
        thumbnailClass='card__voucher'
        title='Gutscheine'
        description='Bestelle Dir hier Deinen individualisierten Onlinegutschein' />
    </div>
  </>;
}

export default App;
