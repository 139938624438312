import { FieldErrors, UseFormRegister, UseFormSetValue } from "react-hook-form";
import { IdentificationData } from "../types/CustomerIdentification";
import Checkbox from "../../../../components/input/Checkbox";

interface CheckboxFormProps {
    register: UseFormRegister<IdentificationData>;
    errors?: FieldErrors;
    setValue: UseFormSetValue<IdentificationData>;
}

export default function CheckboxFormFields(props: CheckboxFormProps) {
    return <>

        {/* Datenschutzerklärung */}
        <Checkbox
            id='dse'
            register={
                props.register("dse", {
                    required: true
                })
            }
            text={`Die abgesendeten Daten werden nur zum Zweck der Bearbeitung Deines Anliegens verarbeitet. Weitere Informationen findest Du in unserer <a href="https://cablesport.de/datenschutzerklaerung/" target="_blank" rel="noreferrer">Datenschutzerklärung</a>.`}
            hasError={Boolean(props.errors?.dse)}
        />
        <Checkbox
            id='legal'
            register={
                props.register("legal", {
                    required: true
                })
            }
            text={`Hiermit akzeptiere ich die <a href="https://cablesport.de/agbs/" target="_blank" rel='noreferrer'>Allgemeinen Geschäftsbedingungen</a> der Cablesport GmbH.`}
            hasError={Boolean(props.errors?.legal)}
        />   </>
}