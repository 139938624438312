import { useState } from "react";
import { H3, H4 } from "../headline/Headline";
import "./faq.css";

interface FAQProps {
    entries: FAQEntry[];
}

export interface FAQEntry {
    headline: string,
    body: JSX.Element
}

export default function FAQ({ entries }: FAQProps) {

    const [activeEntry, setActiveEntry] = useState<string | null>(null);

    const handleClick = (key: string) => {
        if (activeEntry === key) {
            setActiveEntry(null);
        } else {
            setActiveEntry(key);
        }
    }

    interface EntryProps {
        entryKey: string,
        headline: string,
        body: JSX.Element,
        clicked: (entryKey: string) => void;
    }

    function Entry({ entryKey, headline, body, clicked }: EntryProps): JSX.Element {

        let isActive = activeEntry === entryKey;

        return <>
            <div className={isActive ? 'faq-item active' : 'faq-item'}>
                <div className='faq-header' onClick={() => clicked(entryKey)}>
                    <H4 text={headline} />
                </div>
                {isActive && <div className='faq-body'>
                    {body}
                </div>
                }
            </div>
        </>
    }

    return <>
        <div className='faq-wrapper'>
            <H3 text="FAQ's" classname="faq-headline"></H3>
            {entries.map(
                (entry, index) => <Entry key={index} entryKey={index.toString()} headline={entry.headline} body={entry.body} clicked={handleClick} />
            )}
        </div>
    </>

}