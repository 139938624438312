import de from "date-fns/locale/de";
import React, { useEffect, useState } from 'react';
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Buttons } from '../../components/button/Button';
import { InputField } from '../../components/input/InputFields';
import Loader from "../../components/loader/Loader";
import Select from "../../components/select/Select";
import { holidays } from '../../types/Constants';
import { CustomerType } from '../booking/identification/types/CustomerIdentification';
import { TimeSlot, findTimeslots } from "./api/LargeGroupTimeslotApi";

function determineEarliestDate(customerType: CustomerType | undefined): Date {
    let earliestDate = new Date();
    earliestDate.setDate(earliestDate.getDate() + 7);
    if (customerType === 'SCHOOL_OR_CLUB') {
        while (!isWeekday(earliestDate) || holidays.includes(earliestDate)) {
            earliestDate.setDate(earliestDate.getDate() + 1);
        }
    }
    return earliestDate;
}

export interface LargeGroupTicketData {
    start: number;
    end: number;
    attendees: number;
    additionalCoach: boolean;
}

const isWeekday = (date: Date): boolean => {
    const day = date.getDay();
    return day !== 0 && day !== 6;
}

interface LargeGroupBookingFormProps {
    onComplete: (data: LargeGroupTicketData) => void;
    customerType: CustomerType;
}

interface LargeGroupTicketFormData {
    selectedTimeSlot: number;
    numberOfAttendees: number;
    additionalCoach: boolean;
}

function LargeGroupBookingForm({ onComplete, customerType }: LargeGroupBookingFormProps) {

    const maxYear = new Date(Date.UTC(2025, 11, 31));
    const [selectedDate, setSelectedDate] = useState<Date>(determineEarliestDate(customerType));
    const [timeslots, setTimeslots] = useState<TimeSlot[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [totalSum, setTotalSum] = useState<number>(0);

    const { register, handleSubmit, formState: { errors, isSubmitting }, setValue, watch } = useForm<LargeGroupTicketFormData>();

    const onSubmit: SubmitHandler<LargeGroupTicketFormData> = (data: LargeGroupTicketFormData): void => {
        setIsLoading(true);
        onComplete(
            {
                start: timeslots[data.selectedTimeSlot - 1].start,
                end: timeslots[data.selectedTimeSlot - 1].end,
                attendees: data.numberOfAttendees,
                additionalCoach: data.additionalCoach
            }
        );
    };

    const formRegistry = {
        attendees: register("numberOfAttendees", {
            required: true,
            min: 1
        }),
        timeslot: register("selectedTimeSlot", {
            required: true,
            min: 1
        })
    };

    const watchedTimeslot = watch("selectedTimeSlot");
    const watchedAdditionalCoach = watch("additionalCoach", false);
    const watchedAttendees = watch("numberOfAttendees", 0);

    useEffect(() => {
        if (watchedTimeslot) {
            let index = watchedTimeslot - 1;
            if (timeslots[index]) {
                let priceForSlot = timeslots[index].price;
                if (watchedAdditionalCoach) {
                    setTotalSum(priceForSlot + 60);
                } else {
                    setTotalSum(priceForSlot);
                }
            }
        }
    }, [watchedTimeslot, watchedAdditionalCoach, timeslots]);

    useEffect(() => {
        findTimeslots({ customerType: customerType, timestamp: selectedDate.getTime() })
            .then((timeslots: TimeSlot[]) => {
                setTimeslots(timeslots);
                setIsLoading(false);
            })
            .catch(() => {
                setTimeslots([]);
                setIsLoading(false);
                setTotalSum(0);
            });
    }, [selectedDate, customerType]);

    return <>
        <Loader active={isLoading}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="input-wrapper">
                    <label className="label" htmlFor="largeGroupDate">Wunschdatum</label>
                    <DatePicker id="largeGroupDate"
                        name="largeGroupDate"
                        maxDate={maxYear}
                        selected={selectedDate}
                        allowSameDay={false}
                        onChange={(date: Date) => setSelectedDate(date)}
                        locale={de}
                        minDate={determineEarliestDate(customerType)}
                        dateFormat='dd.MM.yyyy'
                        excludeDates={customerType === 'SCHOOL_OR_CLUB' ? holidays : undefined}
                        filterDate={customerType === 'SCHOOL_OR_CLUB' ? isWeekday : undefined}
                        className=''
                    />
                </div>
                <Select
                    name="largeGroupTimeslot"
                    label="Zeitraum"
                    register={formRegistry.timeslot}
                    options={timeslots.map((timeslot, index) => {
                        return {
                            text: `${timeslot.formattedDate} (${timeslot.price} €)`,
                            value: index + 1
                        }
                    })}
                    errorMessage='Wähle bitte einen gültigen Zeitraum aus oder versuche es mit einem anderen Tag'
                    hasError={Boolean(errors?.selectedTimeSlot)}
                    onChange={(value) => setValue("selectedTimeSlot", value === null ? 0 : value)}
                    defaultOption={timeslots.length > 0 ? 'Bitte Zeitraum auswählen' : 'Bitte anderen Tag auswählen'}
                />
                <span className="hint">* Wir reservieren Deinen Wunschtermin für 10 Minuten. Nach Ablauf dieser Zeit wird der Termin wieder freigegeben, falls die Buchung bis dahin nicht abgeschlossen wurde.</span>
                <InputField
                    label="Anzahl der Teilnehmenden"
                    register={formRegistry.attendees}
                    type={'number'}
                    name='numberOfAttendees'
                    placeholder='Voraussichtliche Anzahl der Teilnehmenden'
                    hasError={Boolean(errors?.numberOfAttendees)}
                    errorMessage='Bitte gib hier eine gültige Teilnehmeranzahl an'
                    onChange={(e: React.FormEvent<HTMLInputElement>) => {
                        setValue("numberOfAttendees", parseInt(e.currentTarget.value));
                    }}
                />
                {watchedAttendees > 30 &&
                    <span className="hint"><strong>Achtung:</strong> Wir empfehlen für einen 2 Stunden Slot eine maximale Gruppengröße von 30 Personen</span>
                }
                <span className="hint">* der Preis enthält die Miete für 15 Personen. Jede weitere Person wird am Veranstaltungstag zusätzlich abgerechnet. Die Preise dafür kannst Du aus den FAQs entnehmen.</span>


                <div className='checkbox-wrapper'>
                    <input type='checkbox' id={`additionalCoach`}
                        {...register(`additionalCoach`)}
                    />
                    <label className='form-check-label inline-block' htmlFor={`additionalCoach`}>
                        Intensivbetreuung durch zusätzlichen Coach (60€ Aufpreis)
                    </label>
                </div>

                <div className="priceIndicatorAndButtonWrapper">
                    <span>Aktueller Buchungsbetrag: <strong>{totalSum}€</strong></span>
                    <Buttons
                        submitButtonText='Weiter'
                        submitButtonDisabled={isSubmitting || isLoading}
                    />
                </div>
            </form >
        </Loader>
    </>
}

export default LargeGroupBookingForm;