import { FAQEntry } from "../../../components/faq/FAQ";

export const invertFaq: FAQEntry[] = [
    {
        headline: 'Leistung',
        body: <>
            <p>
                Der Invertkurs beinhaltet ein gemeinsames Warm-Up, eine theoretische Erklärung des „Raleys“ (Sprung aus dem Wasser) oder der „Backrole“ (Sprung aus dem Wasser mit einer Körperrotation), das Trainieren der richtigen Anfahrt und anschließend das Üben der jeweiligen Bewegung mit direktem Feedback von einem unserer Trainer.
                <br />
                <br />
                Maximale Teilnehmeranzahl: 8 Personen
                <br />
                Kurszeit: 3 Stunden
            </p>
        </>
    },
    {
        headline: 'Allgemeine Infos für alle Invertkurse',
        body: <p>
            Das Inverttraining ist ein Angebot für Fortgeschrittene. Ein sicheres Fahren, das Beherrschen des Wasserstarts und eigenes Equipment mit festsitzenden Bindungen ist vorausgesetzt.
            <br /><br />
            Bitte beachtet, dass Ihr vor jedem Invertraining eine halbe Stunde vor der Kurszeit vor Ort seid, damit wir genug Zeit für das Umziehen und die Einweisung haben.
        </p>
    },
    {
        headline: 'Zahlungsoptionen',
        body: <p>Ihr habt die Möglichkeit per Kreditkarte, Sofortüberweisung oder PayPal-Konto zu bezahlen.</p>
    },
    {
        headline: 'Buchung und Stornierung',
        body: <p>
            Ist der Kurs bereits ausgebucht? Dann schreibt uns einfach eine Mail an die <a href='mailto:moinmoin@cablesport.de'>moinmoin@cablesport.de</a> und wir setzen Euch auf die Warteliste.
            <br /><br />
            Wir reservieren den Platz exklusiv für Euch. Nach der Buchung ist eine Stornierung nur möglich, wenn wir einen entsprechenden Ersatz finden können. Schreibt uns hierzu gern eine Mail an die <a href='mailto:moinmoin@cablesport.de'>moinmoin@cablesport.de</a>
            <br /><br />
            Es fallen ggfs. Stornierungsgebühren i.H.v. 15% des Buchungsbetrages an.
        </p>
    }
];