export const holidays: Date[] = [
    new Date(Date.UTC(2023, 3, 7)),
    new Date(Date.UTC(2023, 3, 9)),
    new Date(Date.UTC(2023, 3, 10)),
    new Date(Date.UTC(2023, 4, 1)),
    new Date(Date.UTC(2023, 4, 18)),
    new Date(Date.UTC(2023, 4, 29)),
    new Date(Date.UTC(2023, 9, 3)),
    new Date(Date.UTC(2023, 9, 31)),
    new Date(Date.UTC(2023, 11, 24)),
    new Date(Date.UTC(2023, 11, 25)),
    new Date(Date.UTC(2023, 11, 26)),
    new Date(Date.UTC(2023, 11, 31)),
    // 2024
    new Date(Date.UTC(2024, 0, 1)),
    new Date(Date.UTC(2024, 2, 29)),
    new Date(Date.UTC(2024, 2, 31)),
    new Date(Date.UTC(2024, 3, 4)),
    new Date(Date.UTC(2024, 4, 1)),
    new Date(Date.UTC(2024, 4, 9)),
    new Date(Date.UTC(2024, 4, 20)),
    new Date(Date.UTC(2024, 9, 3)),
    new Date(Date.UTC(2024, 9, 31)),
    new Date(Date.UTC(2024, 11, 24)),
    new Date(Date.UTC(2024, 11, 25)),
    new Date(Date.UTC(2024, 11, 26)),
    new Date(Date.UTC(2024, 11, 31)),
    // 2025
    new Date(Date.UTC(2025, 0, 1)),
    new Date(Date.UTC(2025, 3, 18)),
    new Date(Date.UTC(2025, 3, 20)),
    new Date(Date.UTC(2025, 3, 21)),
    new Date(Date.UTC(2025, 4, 1)),
    new Date(Date.UTC(2025, 4, 29)),
    new Date(Date.UTC(2025, 5, 9)),
    new Date(Date.UTC(2025, 9, 3)),
    new Date(Date.UTC(2025, 9, 31)),
    new Date(Date.UTC(2025, 11, 24)),
    new Date(Date.UTC(2025, 11, 25)),
    new Date(Date.UTC(2025, 11, 26)),
    new Date(Date.UTC(2025, 11, 31))
];