import FAQ from "../../../components/faq/FAQ";
import { alternatives, cancelationInfo, info, prices, pricesSchoolsOrClubs } from "./LargeGroupFaqTexts";

export default function LargeGroupFaq() {
    return <>
        <FAQ entries={
            [
                {
                    headline: 'Preise für Privatpersonen und Firmen',
                    body: prices
                },
                {
                    headline: 'Preise für Schulen und Jugendvereine ***',
                    body: pricesSchoolsOrClubs
                },
                {
                    headline: 'Allgemeine Infos für alle Gruppenmieten',
                    body: info
                },
                {
                    headline: 'Zahlungsoptionen',
                    body: <p>Ihr habt die Möglichkeit per Kreditkarte, Sofortüberweisung oder PayPal-Konto zu bezahlen.</p>
                },
                {
                    headline: 'Buchungs- und Stornierungsfristen',
                    body: cancelationInfo
                },
                {
                    headline: 'Wir sind weniger als 15 Personen, was gibt es für Alternativen?',
                    body: alternatives
                }
            ]
        }
        />
    </>
}