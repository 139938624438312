import { H3 } from "../../../components/headline/Headline";

export default function Error() {
    return <>
        <H3 text="Fehler während der Verarbeitung" />
        <div>
            <p>
                Leider konnten wir Deine Anfrage nicht erfolgreich verarbeiten. Bitte versuche es erneut, indem Du diese Seite neu lädst.
            </p>
        </div>
    </>
}