import { useSelector } from "react-redux";
import { Ticket, Voucher } from "../../types/Ticket";
import BookingFlow from "../booking/BookingFlow";
import VoucherTicketHandler from "./ticket/VoucherTicketHandler";
import VoucherFaq from "./faq/VoucherFaq";
import { RootState } from "../../state/store";

export interface SubmitVoucherRequest {
    credit: number;
    headline: string;
    text: string;
}

export default function VoucherFlow() {

    const savedVoucher: Voucher | undefined = useSelector(
        (state: RootState) => {
            let voucher = state.ticket as Voucher;
            return Object.keys(voucher).length > 0 ? voucher : undefined;
        }
    );

    return <>
        <BookingFlow
            headline="Gutscheine"
            ticket={
                {
                    handler: (onTicketConfigurationCompleted) =>
                        <VoucherTicketHandler
                            onComplete={onTicketConfigurationCompleted}
                            currentVoucher={savedVoucher}
                        />,
                    submitCall: {
                        path: 'ticket/voucher',
                        mapper: (ticket: Ticket) => {
                            let voucher = ticket as Voucher;
                            return {
                                credit: voucher.credit,
                                headline: voucher.headline,
                                text: voucher.text
                            } as SubmitVoucherRequest
                        }
                    }
                }
            }
            customer={
                {
                    types: ['PERSON']
                }
            }
        />
        <VoucherFaq />
    </>

}