
import { useEffect } from 'react';
import { H3 } from '../../../components/headline/Headline';
import { Buttons } from '../../../components/button/Button';

interface PaymentFormProps {
    paymentId: string;
    onPaymentCompleted: () => void;
}

const { REACT_APP_CHECKOUT_KEY } = process.env;

declare var Dibs: any;

function PaymentForm({ onPaymentCompleted, paymentId }: PaymentFormProps) {

    useEffect(() => {
        const checkoutOptions = {
            checkoutKey: REACT_APP_CHECKOUT_KEY,
            paymentId: paymentId,
            containerId: "checkout-container-div",
            language: "de-DE"
        };
        const checkout = new Dibs.Checkout(checkoutOptions);
        checkout.on('payment-completed', () => {
            onPaymentCompleted();
            window.location.hash = '/abschluss';
        });
    }, [onPaymentCompleted, paymentId])



    return <>
        <H3 text={`Zahlung durchführen`} />
        <div id={`checkout-container-div`}></div>
        <Buttons />
    </>
}

export default PaymentForm;