import { useLoaderData } from "react-router-dom";
import Card from "../../../components/card/Card";
import { H3 } from "../../../components/headline/Headline";
import { CourseCategory } from "../api/CourseApi";
import './CourseCategories.css';

export default function CourseCategories() {

    const categories: any = useLoaderData();

    return <>
        {
            categories.length ? (<>
                <H3 text="Unser Angebot" />
                <div className="cards">
                    {
                        categories.map((category: CourseCategory) =>
                            <Card
                                key={category.segment}
                                linkTo={'/kurse/' + category.segment}
                                thumbnailClass={`course-category__${category.segment}`}
                                title={category.title}
                                description={category.description}
                            />
                        )
                    }
                </div>
            </>) : (<>Keine Kategorien gefunden</>)
        }
    </>

}