import { UseFormRegisterReturn } from "react-hook-form";

interface CheckboxProps {
    id: string;
    register: UseFormRegisterReturn;
    text: string;
    hasError: boolean;
}

export default function Checkbox(props: CheckboxProps) {
    return <div className='checkbox-wrapper'>
        <input type='checkbox' id={props.id}
            className={props.hasError ? 'input-error' : 'input-valid'}
            {...props.register}
        />
        <label className='form-check-label inline-block' htmlFor={props.id} dangerouslySetInnerHTML={{ __html: props.text }} />
    </div>
} 