import { H3 } from "../../../components/headline/Headline";

export default function Complete() {

    return <>
        <H3 text="Vielen Dank" />
        <div>
            <p>
                Vielen Dank für die Buchung bei Cablesport.<br />
                <br />
                Du erhälst in Kürze eine E-Mail mit allen nötigen Infos und der Buchungsbestätigung.<br />
                Solltest Du keine E-Mail erhalten oder weitere Fragen haben, dann melde dich gern unter <a href="mailto:moinmoin@cablesport.de">moinmoin@cablesport.de</a>
                <br />
                <br />
                Dein CAP Team
            </p>

        </div>
    </>
}  