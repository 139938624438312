import { ReactComponent as CapLogo } from './logo.svg'
import './header.css'

export default function Header() {

    return <>
        <header>
            <div className="logo">
                <a href="/">
                    <CapLogo />
                </a>
            </div>
        </header>
    </>
}