import { useEffect, useState } from "react";
import { Voucher } from "../../../types/Ticket"
import VoucherForm from "./VoucherForm";
import VoucherPreview from "./preview/VoucherPreview";

interface VoucherTicketHandlerProps {
    onComplete: (ticket: Voucher) => void;
    currentVoucher?: Voucher;
}

export default function VoucherTicketHandler(props: VoucherTicketHandlerProps) {

    const [voucher, setVoucher] = useState<Voucher | undefined>(props.currentVoucher);
    const [step, setStep] = useState<'FORM' | 'PREVIEW'>('FORM');

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [step]);

    return <>
        {
            step === 'FORM' &&
            <VoucherForm
                onComplete={
                    (ticket: Voucher) => {
                        setVoucher(ticket);
                        setStep('PREVIEW');
                    }
                }
                voucher={voucher}
            />
        }
        {
            step === 'PREVIEW' &&
            <VoucherPreview
                voucher={voucher}
                onCancel={() => setStep('FORM')}
                onComplete={() => {
                    if (voucher) {
                        props.onComplete(voucher);
                    }
                }}
            />
        }
    </>
}