import { FieldErrors, UseFormRegister, UseFormSetValue } from "react-hook-form";
import { InputField } from "../../../../components/input/InputFields";
import { IdentificationData } from "../types/CustomerIdentification";

interface AddressProps {
    register: UseFormRegister<IdentificationData>;
    errors: FieldErrors;
    setValue: UseFormSetValue<IdentificationData>;
}

export default function AddressFormFields(props: AddressProps) {
    return <>
        <InputField
            label='Straße und Hausnummer (optional)'
            register={props.register("street")}
            placeholder='Straße und Hausnummer'
            name='street'
            hasError={false}
            errorMessage=''
            onChange={(e: React.FormEvent<HTMLInputElement>) => {
                props.setValue("street", e.currentTarget.value);
            }} />
        <InputField
            label='Ort (optional)'
            register={props.register("city")}
            placeholder='Ort'
            name='city'
            hasError={false}
            errorMessage=''
            onChange={(e: React.FormEvent<HTMLInputElement>) => {
                props.setValue("city", e.currentTarget.value);
            }} />
        <InputField
            label='Postleitzahl (optional)'
            register={props.register("zip")}
            placeholder='Postleitzahl'
            name='zip'
            hasError={false}
            errorMessage=''
            onChange={(e: React.FormEvent<HTMLInputElement>) => {
                props.setValue("zip", e.currentTarget.value);
            }} />
    </>
}