import CookieConsent from "react-cookie-consent";
import './consentBanner.css';


export default function ConsentBanner() {
    return <CookieConsent
        overlay={true}
        location='top'
        buttonText='OK'
        cookieName='cablesport-tickets-consent'
        cookieSecurity={true}
        buttonClasses='consent-button'
        containerClasses='consent-banner'
        disableStyles={true}
    >
        Diese Website benutzt Cookies. Wenn du die Website weiter nutzt, gehen wir von deinem Einverständnis aus.
    </CookieConsent>
}
