import { CustomerType } from "../../booking/identification/types/CustomerIdentification";

export interface TimeSlot {
    formattedDate: string;
    start: number;
    end: number;
    price: number;
}

export interface TimeSlotRequest {
    customerType: CustomerType;
    timestamp: number;
}

export const findTimeslots = (request: TimeSlotRequest): Promise<TimeSlot[]> => {
    return fetch('/api/booking/largeGroup/timeslots',
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify(request)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
            return Promise.reject(response.status);
        });
}