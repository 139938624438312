export interface CashInVoucherRequest {
    code: string;
}

export function cashInVoucher(bookingId: string, data: CashInVoucherRequest): Promise<boolean> {
    return fetch(`/api/booking/${bookingId}/voucher/cashin`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        body: JSON.stringify(data)
    }).then(r => {
        if (!r.ok) {
            return false;
        } else {
            return true;
        }
    })
}