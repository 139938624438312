import FAQ, { FAQEntry } from "../../../components/faq/FAQ";
import { anfaengerFaq } from "./AnfaengerFaq";
import { invertFaq } from "./InvertFaq";
import { sommerFerienFaq } from "./SommerferienFaq";

interface CourseFaqProps {
    segment?: string;
}

const courseFaqs = new Map<string, FAQEntry[]>([
    ['anfaenger', anfaengerFaq],
    ['invert', invertFaq],
    ['sommerferien', sommerFerienFaq]
]);

export default function CourseFaq({ segment }: CourseFaqProps) {
    let faqs: FAQEntry[] | undefined = segment ? courseFaqs.get(segment) : undefined;

    return <>{faqs && <FAQ entries={faqs} />}</>
}