export interface RequestPaymentResponse {
    required: boolean;
    paymentId: string;
}

export const requestPayment = (bookingId: string): Promise<RequestPaymentResponse> => {
    return fetch('/api/booking/' + bookingId + '/payment',
        {
            method: 'POST',
            headers: {
                'Accept': 'application/json'
            }
        })
        .then(res => {
            if (res.ok) {
                return res.json();
            } else {
                return Promise.reject(res.status);
            }
        });
}