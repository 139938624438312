import { SubmitHandler, useForm } from 'react-hook-form';
import { Buttons } from '../../../components/button/Button';
import { H3 } from '../../../components/headline/Headline';
import { InputField } from '../../../components/input/InputFields';
import { Customer } from '../../../types/Customer';
import CheckboxFormFields from './forms/CheckboxFormFields';
import { PATTERN_COMPANY_OR_SCHOOLNAME, PATTERN_EMAIL, PATTERN_NAME, PATTERN_PHONE_NR } from './types';
import { CustomerType, IdentificationData } from './types/CustomerIdentification';
import AddressFormFields from './forms/AddressFormFields';

interface CustomerIdentificationFormProps {
    onComplete: (data: Customer) => void;
    onCancel: () => void;
    customerType: CustomerType;
}

function CustomerIdentificationForm(props: CustomerIdentificationFormProps) {

    const { register, handleSubmit, formState: { errors }, setValue } = useForm<IdentificationData>();

    const onSubmit: SubmitHandler<IdentificationData> = data => {
        props.onComplete({
            customerType: props.customerType,
            ...data
        });
    };

    const isCompany = props.customerType === 'COMPANY';

    return <>
        <H3 text="Wir benötigen folgende Daten für die Buchung" />
        <form onSubmit={handleSubmit(onSubmit)}>
            {
                props.customerType !== 'PERSON' &&
                <InputField
                    label={isCompany ? 'Firma' : 'Name der Schule / des Jugendvereins'}
                    register={register('additionalName', { required: true, pattern: PATTERN_COMPANY_OR_SCHOOLNAME })}
                    placeholder={isCompany ? 'Firmenname' : 'Name der Schule / des Jugendvereins'}
                    name='additionalName'
                    hasError={Boolean(errors?.additionalName)}
                    errorMessage={isCompany ? 'Bitte gib den Namen der Firma an' : 'Bitte gib den Namen der Schule / des Jugendvereins an'}
                    onChange={(e: React.FormEvent<HTMLInputElement>) => {
                        setValue("additionalName", e.currentTarget.value);
                    }} />
            }

            {/* Vorname */}
            <InputField
                label='Vorname'
                register={register('firstName', { required: true, pattern: PATTERN_NAME })}
                placeholder='Vorname'
                name='firstName'
                hasError={Boolean(errors?.firstName)}
                errorMessage='Bitte gib Deinen Vornamen an'
                onChange={(e: React.FormEvent<HTMLInputElement>) => {
                    setValue("firstName", e.currentTarget.value);
                }} />

            {/* Nachname */}
            <InputField
                label='Nachname'
                register={register('lastName', { required: true, pattern: PATTERN_NAME })}
                placeholder='Nachname'
                name='lastName'
                hasError={Boolean(errors?.lastName)}
                errorMessage='Bitte gib Deinen Nachnamen an'
                onChange={(e: React.FormEvent<HTMLInputElement>) => {
                    setValue("lastName", e.currentTarget.value);
                }} />

            {/* E-Mail */}
            <InputField
                label='E-Mail'
                register={register('email', { required: true, pattern: PATTERN_EMAIL })}
                placeholder='E-Mail'
                name='email'
                hasError={Boolean(errors?.email)}
                errorMessage='Bitte gib Deine E-Mail-Adresse an'
                onChange={(e: React.FormEvent<HTMLInputElement>) => {
                    setValue("email", e.currentTarget.value);
                }} />

            {/* Mobilnr */}
            <InputField
                label='Telefonnummer'
                register={register('mobileNr', { required: true, pattern: PATTERN_PHONE_NR })}
                placeholder='Telefonnummer für Rückfragen'
                name='mobile'
                hasError={Boolean(errors?.mobileNr)}
                errorMessage='Bitte gib Deine Telefonnummer an'
                onChange={(e: React.FormEvent<HTMLInputElement>) => {
                    setValue("mobileNr", e.currentTarget.value);
                }} />

            {
                props.customerType !== 'PERSON' &&
                <AddressFormFields
                    register={register}
                    errors={errors}
                    setValue={setValue}
                />
            }

            <CheckboxFormFields
                register={register} errors={errors} setValue={setValue} />

            <Buttons
                backButtonAction={props.onCancel}
                backButtonText='Zurück'
                submitButtonText='Weiter' />
        </form>
    </>
}

export default CustomerIdentificationForm;