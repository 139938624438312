import { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { H2 } from "../../components/headline/Headline";

export default function Courses() {

    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return <>
        <H2 text='Kurse buchen' />
        <Outlet />
    </>
}