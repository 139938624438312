import { useLoaderData, useParams } from "react-router-dom";
import { CourseTicket, Ticket } from "../../types/Ticket";
import BookingFlow from "../booking/BookingFlow";
import { Course, CourseParticipant, SubmitCourseTicketRequest } from "./api/CourseApi";
import CourseFaq from "./faq/CourseFaq";
import CourseTicketForm from "./form/CourseTicketForm";

export default function CourseBookingFlow() {

    const params = useParams();
    const course = useLoaderData() as Course;

    return <>
        {
            course ?
                <BookingFlow
                    ticket={
                        {
                            handler: (onTicketConfigurationCompleted) =>
                                <CourseTicketForm
                                    onComplete={
                                        (participants: CourseParticipant[]) =>
                                            onTicketConfigurationCompleted(
                                                {
                                                    course: course.reference,
                                                    type: 'Course',
                                                    participants: participants
                                                } as CourseTicket
                                            )
                                    }
                                    course={course}
                                />,
                            submitCall: {
                                path: 'ticket/course',
                                mapper: (ticket: Ticket) => {
                                    let courseTicket = ticket as CourseTicket;
                                    return {
                                        course: courseTicket.course,
                                        participants: courseTicket.participants
                                    } as SubmitCourseTicketRequest
                                }
                            }
                        }
                    }
                    customer={
                        {
                            types: ['PERSON']
                        }
                    }
                />
                : <>Leider konnten wir den Kurs nicht finden</>
        }
        <CourseFaq segment={params.segment} />
    </>
}