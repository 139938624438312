import { FAQEntry } from "../../../components/faq/FAQ";

export const sommerFerienFaq: FAQEntry[] = [
    {
        headline: 'Leistung',
        body: <>
            <p>
                Unser Ferienkurs ist ein Angebot für Kids bis 16 Jahre. Ein sicheres Schwimmen wird vorausgesetzt und ist Pflicht, ein Mindestalter besteht allerdings nicht.
                <br />
                Eine ggf. notwendige Einweisung: Inklusive
                <br /><br />
                Maximale Teilnehmeranzahl: 30 Personen
                <br />Kurszeit: 2 Stunden
            </p>
        </>
    },
    {
        headline: 'Allgemeine Infos für alle Sommerferienkurse',
        body: <p>
            Der Sommerferienkurs ist für alle Schüler bis 16 Jahre. Ein sicheres Schwimmen wird vorausgesetzt und ist Pflicht, ein Mindestalter besteht allerdings nicht.
            <br /><br />
            Bitte beachtet, dass Ihr vor jedem Anfängerkurs eine halbe Stunde vor der Kurszeit vor Ort seid, damit wir genug Zeit für das Umziehen und die Einweisung haben.
        </p>
    },
    {
        headline: 'Zahlungsoptionen',
        body: <p>Ihr habt die Möglichkeit per Kreditkarte, Sofortüberweisung oder PayPal-Konto zu bezahlen.</p>
    },
    {
        headline: 'Buchung und Stornierung',
        body: <p>
            Ist der Kurs bereits ausgebucht? Dann schreibt uns einfach eine Mail an die <a href='mailto:moinmoin@cablesport.de'>moinmoin@cablesport.de</a> und wir setzen Euch auf die Warteliste.
            <br /><br />
            Wir reservieren den Platz exklusiv für Euch. Nach der Buchung ist eine Stornierung nur möglich, wenn wir einen entsprechenden Ersatz finden können. Schreibt uns hierzu gern eine Mail an die <a href='mailto:moinmoin@cablesport.de'>moinmoin@cablesport.de</a>
            <br /><br />
            Es fallen ggfs. Stornierungsgebühren i.H.v. 15% des Buchungsbetrages an.
        </p>
    }
];