import { useForm } from "react-hook-form";
import { InputField } from "../../../../components/input/InputFields";
import { cashInVoucher } from "../api/CashInVoucher";

interface CashInVoucherFormProps {
    bookingId: string;
    onValidVoucherCode: () => void;
}

interface CashedInVoucher {
    code: string;
}

export default function CashInVoucherForm(props: CashInVoucherFormProps) {

    const { register, formState: { errors }, handleSubmit, setError } = useForm<CashedInVoucher>();

    function voucherCodeSubmitted(data: CashedInVoucher) {
        if (data.code) {
            cashInVoucher(props.bookingId, { code: data.code.toUpperCase() })
                .then(success => success ? props.onValidVoucherCode() : setError('code', { type: "pattern" }, { shouldFocus: true }))
        }
    }

    return <>
        <form onSubmit={handleSubmit(voucherCodeSubmitted)} className="cashInVoucherForm">
            <InputField
                register={register('code', { required: false })}
                label="Gutscheincode (optional)"
                name="code"
                placeholder=""
                hasError={Boolean(errors?.code)}
                errorMessage="Der Gutschein ist nicht gültig oder ohne Restwert"
                additionalClasses="cashInVoucherField"
            />
            <button className="cashInVoucherButton" type="submit">Gutschein einlösen</button>
        </form>
    </>
}