import { useState } from "react";
import { useLoaderData, useNavigate, useParams } from "react-router-dom";
import { H3 } from "../../../components/headline/Headline";
import { Course } from "../api/CourseApi";
import "./CourseList.css";
import CourseFaq from "../faq/CourseFaq";

export default function CourseList() {

    const courses: any = useLoaderData();
    const params = useParams();
    const navigate = useNavigate();

    interface CourseProps {
        course: Course;
    }
    function Course({ course }: CourseProps) {
        const [active, setActive] = useState<boolean>(false);

        return <>
            <div className="course-wrapper" onClick={() => setActive(!active)}>
                <div className={`course-header`}>
                    <div className="course-column">{course.date}</div>
                    <div className="course-column-">Freie Plätze: {course.remainingSpots}</div>
                </div>
                <div className="course-content">
                    {course.hasEquipmentOption ? (
                        <>
                            <div className="course-row">
                                <div className="course-column">{course.title}</div>
                                <div className="course-column">inkl. Equipment: {course.price + course.priceOfEquipment} € / Person</div>
                            </div>
                            <div className="course-row content-aligned-at-end">
                                <div className="course-column">ohne Equipment: {course.price} € / Person</div>
                            </div>
                        </>
                    ) : (
                        <div className="course-row">
                            <div className="course-column">{course.title}</div>
                            <div className="course-column">{course.price} € / Person</div>
                        </div>
                    )
                    }
                    <div className="course-row">
                        <div className="course-description" dangerouslySetInnerHTML={{ __html: course.description }}></div>
                    </div>
                    <div className="course-row content-aligned-at-end">
                        <button className="course-button" onClick={() => navigate('/kurse/' + params.segment + '/' + course.reference)} disabled={course.remainingSpots === 0}>{course.remainingSpots > 0 ? 'Buchen' : 'Ausgebucht'}</button>
                    </div>
                </div>
            </div >
        </>
    }

    return <>
        <H3 text='Wähle deinen Wunschtermin aus' />
        {courses.length ? (
            <>

                {
                    courses.map((course: Course) =>
                        <Course course={course} key={course.reference} />
                    )
                }
            </>
        ) : (
            <span>Zur Zeit bieten wir keinen dieser Kurse online an. Bitte wende dich für weitere Infos an <a href="mailto:moinmoin@cablesport.de">moinmoin@cablesport.de</a>.</span>
        )
        }
        <CourseFaq segment={params.segment} />
    </>
}