import { FieldArrayWithId, useFieldArray, useForm } from "react-hook-form";
import { Buttons } from "../../../components/button/Button";
import { H3 } from "../../../components/headline/Headline";
import { InputField } from "../../../components/input/InputFields";
import { Course, CourseParticipant } from "../api/CourseApi";
import "./CourseTicketForm.css";
import { useEffect, useState } from "react";

type CourseTicketFormValues = {
    participants: {
        firstName: string;
        age: number;
        needsEquipment: boolean;
        needsIntroduction: boolean;
        needsObstacleEquipment: boolean;
    }[]
}

interface CourseTicketFormProps {
    onComplete: (participants: CourseParticipant[]) => void;
    course: Course;
}

function computeTotalSum(fields: FieldArrayWithId[], props: CourseTicketFormProps): number {
    let totalPrice = fields.length * props.course.price;
    fields.forEach((field, index) => {
        if (isOptionSelected('beginners_equipment_' + index)) {
            totalPrice += props.course.priceOfEquipment;
        }
        if (isOptionSelected('obstacle_equipment_' + index)) {
            totalPrice += props.course.priceOfObstacleEquipment;
        }
    })
    return totalPrice;
}

function isOptionSelected(id: string): boolean {
    let checkbox = document.getElementById(id);
    return (checkbox && (checkbox as HTMLInputElement).checked) ? true : false;
}

export default function CourseTicketForm(props: CourseTicketFormProps) {

    const { register, formState: { errors }, control, handleSubmit } = useForm<CourseTicketFormValues>({
        defaultValues: {
            participants: [{ firstName: '', age: undefined, needsEquipment: false, needsIntroduction: false, needsObstacleEquipment: false }]
        }
    });
    const { fields, append, remove } = useFieldArray({
        control,
        name: 'participants'
    });

    const [totalSum, setTotalSum] = useState<number>(computeTotalSum(fields, props));

    useEffect(() => setTotalSum(computeTotalSum(fields, props)), [fields, props]);

    return <>
        <H3 text='Lege die Teilnehmenden fest' />

        <form onSubmit={handleSubmit((participants) => props.onComplete(participants.participants))}>
            <div className="course-participant-form-wrapper">
                {fields.map((field, index) => {
                    return <div className="course-participant-form" key={field.id}>
                        <div className="course-participant">
                            <InputField
                                additionalClasses="participant-name"
                                register={register(`participants.${index}.firstName`, { required: true })}
                                placeholder="Vorname"
                                label="Vorname"
                                name={`participants.${index}.firstName`}
                                errorMessage="Bitte gib hier den Vornamen der teilnehmenden Person an"
                                hasError={Boolean(errors?.participants?.[index]?.firstName)}
                            />
                            <InputField
                                additionalClasses="participant-age"
                                register={register(`participants.${index}.age`, { required: true, min: 1, valueAsNumber: true })}
                                type="number"
                                placeholder="10"
                                label="Alter"
                                name={`participants.${index}.age`}
                                errorMessage="Bitte gib hier das Alter der teilnehmenden Person an"
                                hasError={Boolean(errors?.participants?.[index]?.age)}
                            />
                            {index > 0 && <button type="button" className="remove-participant-button"
                                onClick={
                                    () => {
                                        remove(index);
                                    }
                                }>X</button>}
                        </div>
                        {props.course.hasEquipmentOption &&
                            <div className='checkbox-wrapper'>
                                <input type='checkbox' id={`beginners_equipment_${index}`}
                                    {...register(`participants.${index}.needsEquipment`)}
                                    onClick={() => setTotalSum(computeTotalSum(fields, props))}
                                    disabled={isOptionSelected(`obstacle_equipment_${index}`)}
                                />
                                <label className='form-check-label inline-block' htmlFor={`beginners_equipment_${index}`}>
                                    benötigt Anfänger-Equipment ({props.course.priceOfEquipment} € Aufpreis)
                                </label>
                            </div>
                        }
                        {props.course.hasIntroductionOption &&
                            <div className='checkbox-wrapper'>
                                <input type='checkbox' id={`introduction_${index}`}
                                    {...register(`participants.${index}.needsIntroduction`)} />
                                <label className='form-check-label inline-block' htmlFor={`introduction_${index}`}>
                                    benötigt eine Einweisung
                                </label>
                            </div>
                        }
                        {props.course.hasObstacleEquipmentOption &&
                            <div className='checkbox-wrapper'>
                                <input type='checkbox' id={`obstacle_equipment_${index}`}
                                    {...register(`participants.${index}.needsObstacleEquipment`)}
                                    onClick={() => setTotalSum(computeTotalSum(fields, props))}
                                    disabled={isOptionSelected(`beginners_equipment_${index}`)}
                                />
                                <label className='form-check-label inline-block' htmlFor={`obstacle_equipment_${index}`}>
                                    benötigt Obstacle-Equipment ({props.course.priceOfObstacleEquipment} € Aufpreis)
                                </label>
                            </div>
                        }
                    </div>
                })}
                {props.course.remainingSpots > fields.length &&
                    <button type="button" className="add-participants-button"
                        onClick={
                            () => {
                                append({ firstName: '', age: 0, needsEquipment: false, needsIntroduction: false, needsObstacleEquipment: false });
                            }
                        }
                    >
                        Weitere Person
                    </button>
                }
            </div>
            <div className="priceIndicatorAndButtonWrapper">
                <span>Aktueller Buchungsbetrag: <strong>{totalSum}€</strong></span>
                <Buttons submitButtonText="Weiter" />
            </div>
        </form >
    </>
}